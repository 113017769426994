<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
 *{
  padding: 0;
  margin: 0;
 }
 /* body{
  overflow: hidden;
  font-family: "PangMenZhengDao";
 } */
 
</style>
